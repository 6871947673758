* {
  box-sizing: border-box;
}

body {
  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "SF Pro Display Regular" !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .carousel .control-dots {
  margin: 118px 0 !important
}

.carousel .control-dots .dot.selected {
  width: 36px;
  height: 8px;
  border-radius: 24px;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;


.ndfHFb-c4YZDc-i5oIFb.ndfHFb-c4YZDc-e1YmVc .ndfHFb-c4YZDc-Wrql6b{
  opacity: 0;
}
/* Entire scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Thumb */
::-webkit-scrollbar-thumb {
  background: rgb(163, 163, 163);
  border-radius: 0px;
  border: 0px solid #f1f1f1;
}

/* Thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(179, 179, 179);
}
